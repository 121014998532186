<template>
  <div class="p-0 container-fluid mt-0">
    <div class="col">
      <!-- <div class="row justify-content-center pb-1 text-black">
        দুর্দান্ত খবর !! একই অ্যাপ এ সব কিছু? eপরিষেবা - এখন এসে গিয়েছে দুর্গাপুরে ,
        বালুরঘাটে , গঙ্গারামপুরে, মালদা, শিলিগুড়ি ও মেদিনীপুরে ।।। এখন আপনার শহরেও
        <a
          target="_blank"
          class="ml-3 download-text-black-color"
          href="https://play.google.com/store/apps/details?id=com.eparisheva.client&hl=en_IN&gl=US"
        >
          Download Here
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Top-Banner-Text",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.download-text-black-color {
  color: black !important;
  text-decoration: underline;
  font-weight: bold;
}
@media (max-width: 768px) {
  .downloadhere-mobile-view {
    display: none !important;
  }
}
</style>
